.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.admin-wrapper {
    max-width: 350px;
    min-height: 500px;
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.admin-wrapper .logo {
    width: 80px;
    margin: auto;
}

.admin-wrapper .logo img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.admin-wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.admin-wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.admin-wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.admin-wrapper .form-field .fas {
    color: #555;
}

.admin-wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.admin-wrapper .btn:hover {
    background-color: #039BE5;
}

.admin-wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.admin-wrapper a:hover {
    color: #039BE5;
}

@media(max-width: 380px) {
    .admin-wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
    .dashboard-wrapper{
        margin: 15px 0px 0px 0px !important;
    }
    .adminpage-container {
      overflow-x: auto;
    }
    .admin-sidebar{
        display: none !important;
    }
}

.dashboard-wrapper{
    margin:25px;
}
.admin-sidebar ul li.current{
  background-color: #7DA0FA;
}
.admin-sidebar ul li:hover{
  background-color: #7DA0FA;
}

@media(min-width: 380px) {
    .dashboard-wrapper .nav-outer{
        display: none !important;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.headerwrap{
  display: inline-block;
  width: 100%;
}

.headerwrap a{
	float: left;
}

.admin-sidebar{
  border-radius: 1.1rem;
}

.admin-sidebar .dropdown{
  margin-left: 16px;
}

.admin-sidebar .dropdown li button{
  margin-left: 16px;
}

.adminpage-container{
    padding: 1rem!important;
    border-radius: 1.1rem;
}

.btn{
  margin:5px;
}


.stretch-card {
    -webkit-align-items: stretch;
    -webkit-justify-content: stretch;
    justify-content: stretch;
}

.card.card-tale {
    background: #7DA0FA;
    color: #ffffff;
}

.stretch-card > .card {
    width: 100%;
    min-width: 100%;
}

.card .card-body {
    padding: 1.25rem 1.25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.fs-30 {
    font-size: 30px;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.card.card-dark-blue {
    background: #4747A1;
    color: #ffffff;
}

.card.card-light-danger {
    background: #F3797E;
    color: #ffffff;
}

.card.card-light-blue {
    background: #7978E9;
    color: #ffffff;
}

.card.card-light-purple {
    background: #6a008a;
    color: #ffffff;
}

.items{
  padding: 2% 0%;
}

.items .card {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    border: 0;
    border-radius: 1rem
}

.items .card-img,
.items .card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px)
}

.items .card h5 {
    overflow: hidden;
    font-weight: 900;
    font-size: 1rem
}

.items .card-img-top {
    width: 100%;
    max-height: 180px;
    object-fit: contain;
    padding: 30px
}

.items .card h2 {
    font-size: 1rem
}

.items .card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)
}

.items .label-top {
    position: absolute;
    background-color: #8bc34a;
    color: #fff;
    top: 8px;
    right: 8px;
    padding: 5px 10px 5px 10px;
    font-size: .7rem;
    font-weight: 600;
    border-radius: 3px;
    text-transform: uppercase
}


@media (max-width: 768px) {
    .items .card-img-top {
        max-height: 250px
    }
}


.items .btn {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 50px 5px 50px
}

@media (max-width: 1025px) {
    .items .btn {
        padding: 5px 40px 5px 40px
    }
}

@media (max-width: 250px) {
    .items .btn {
        padding: 5px 30px 5px 30px
    }
}

.items .btn-warning {
    background: none #f7810a;
    color: #ffffff;
    fill: #ffffff;
    border: none;
    text-decoration: none;
    outline: 0;
    box-shadow: -1px 6px 19px rgba(247, 129, 10, 0.25);
    border-radius: 100px
}

.items .btn-warning:hover {
    background: none #ff962b;
    color: #ffffff;
    box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35)
}

.items .bg-success {
    font-size: 1rem;
    background-color: #f7810a !important
}

@media (max-width: 370px) {
    .items .btn {
        padding: 5px 40px 5px 40px;
        font-size: 1rem
    }
}

/*****************Item Detail*************/

.itemDetal img {
  max-width: 100%; }

.itemDetal .preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 996px) {
    .itemDetal .preview {
      margin-bottom: 20px; } }

.itemDetal .preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.itemDetal .preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px; }
  .itemDetal .preview-thumbnail.nav-tabs li {
    width: 18%;
    margin-right: 2.5%; }
    .itemDetal .preview-thumbnail.nav-tabs li img {
      max-width: 100%;
      display: block; }
    .itemDetal .preview-thumbnail.nav-tabs li a {
      padding: 0;
      margin: 0; }
    .itemDetal .preview-thumbnail.nav-tabs li:last-of-type {
      margin-right: 0; }

.itemDetal .tab-content {
  overflow: hidden; }
  .itemDetal .tab-content img {
    width: 100%;
    -webkit-animation-name: opacity;
            animation-name: opacity;
    -webkit-animation-duration: .3s;
            animation-duration: .3s; }

.itemDetal .card {
  margin-top: 10px;
  background: #f5f5f7;
  padding: 2em;
  line-height: 1.5em;
  margin-bottom: 3rem!important;
}

@media screen and (min-width: 997px) {
  .itemDetal .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

.itemDetal .details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.itemDetal .colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.itemDetal .product-title, .itemDetal .price, .itemDetal .sizes, .itemDetal .colors {
  text-transform: UPPERCASE;
  font-weight: bold; }

.itemDetal .checked, .itemDetal .price span {
  color: #ff9f1a; }

.itemDetal .product-title, .itemDetal .rating, .itemDetal .product-description, .itemDetal .price, .itemDetal .sizes {
  margin-bottom: 15px; }

.itemDetal .product-title {
  margin-top: 0; }

.itemDetal .size {
  margin-right: 10px; }
  .itemDetal .size:first-of-type {
    margin-left: 40px; }

.itemDetal .color {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  height: 2em;
  width: 2em;
  border-radius: 2px; }
  .itemDetal .color:first-of-type {
    margin-left: 20px; }

.itemDetal .add-to-cart, .itemDetal .like {
  background: #ff9f1a;
  padding: 1.2em 1.5em;
  border: none;
  text-transform: UPPERCASE;
  font-weight: bold;
  color: #fff;
  -webkit-transition: background .3s ease;
          transition: background .3s ease; }
  .itemDetal .add-to-cart:hover, .itemDetal .like:hover {
    background: #b36800;
    color: #fff; }

.itemDetal .not-available {
  text-align: center;
  line-height: 2em; }
  .itemDetal .not-available:before {
    font-family: fontawesome;
    content: "\f00d";
    color: #fff; }

.itemDetal .orange {
  background: #ff9f1a; }

.itemDetal .green {
  background: #85ad00; }

.itemDetal .blue {
  background: #0076ad; }

.itemDetal .tooltip-inner {
  padding: 1.3em; }

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
            transform: scale(3); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
            transform: scale(3); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*# sourceMappingURL=style.css.map */

/************* Cart Items, Order Confirm *************/
.cartItems, .OrderConfirm{
    margin-bottom: 3rem !important;
}

.cartItems .left, .OrderConfirm .left{
    padding: 2%;
}

.cartItems .empty, .OrderConfirm .empty{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.cartItems .heading40, .OrderConfirm .heading40 {
    margin-bottom: 25px;
}

.cartItems .heading40 h1, .OrderConfirm .heading40 h1 {
    letter-spacing: 0.05em;
    font-size: 24px;
    font-weight: 600;
}
/************* Cart Items, Order Confirm Ends ********************/

/************* Order Shipping, Order Payment, Order Success *************/
.OrderShipping, .OrderPayment, .OrderSuccess{
    margin-bottom: 3rem !important;
}

.OrderShipping .heading40, .OrderPayment .heading40, .OrderSuccess .heading40 {
    margin-bottom: 25px;
}

.OrderShipping .heading40 h1, .OrderPayment .heading40 h1, .OrderSuccess .heading40 h1 {
    letter-spacing: 0.05em;
    font-size: 24px;
    font-weight: 600;
}
/************* Order Shipping, Order Payment, , Order Success Ends ********************/



